<template>
  <div class="pop-form">
    <div class="pop-form-header">
      <!-- <span v-if="type<3">产前规划送审与验收申请</span>
            <span v-if="type==3">企业退规申请 </span>
            <span v-if="type==4">企业入规申请</span> -->
      <span>{{ popTitle }}</span>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="formCheck"
      label-width="125px"
      class="login-form"
    >
      <div class="form-content">
        <el-form-item
          v-if="popTitle == '产线规划送审与验收申请'"
          prop="info.prod_line_plan_type"
          label="申请类型："
        >
          <el-radio-group v-model="ruleForm.info.prod_line_plan_type">
            <el-radio :label="1">送审</el-radio>
            <el-radio :label="2">验收</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '金融产品申请'"
          prop="info.finance_pro_type"
          label="申请类型："
        >
          <el-radio-group v-model="ruleForm.info.finance_pro_type">
            <el-radio :label="1">入园进区贷</el-radio>
            <el-radio :label="2">融资租赁贴息</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="flex flex-align-center">
          <span class="form-text-left">企业名称：</span>
          <template class="flex-1" v-if="ruleForm.info.company_name">{{
            ruleForm.info.company_name
          }}</template>
          <el-input
            class="flex-1"
            v-else
            style="max-width: 300px"
            v-model="ruleForm.info.company_name"
            placeholder="请输入企业名称"
          ></el-input>
        </div>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.company_address"
          label="企业地址："
        >
          <el-input
            style="max-width: 300px"
            v-model="ruleForm.info.company_address"
            placeholder="请输入企业地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.compRange"
          label="企业服务范围："
        >
          <el-input
            style="max-width: 300px"
            v-model="ruleForm.info.compRange"
            placeholder="企业服务范围"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.finance_pro_type"
          label="是否为规上企业"
        >
          <el-radio-group v-model="ruleForm.info.finance_pro_type">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="flex flex-align-center">
          <span class="form-text-left">法人姓名：</span>
          <!-- <template v-if="ruleForm.legal_name">{{ruleForm.legal_name}}</template> -->
          <el-input
            class="flex-1"
            style="max-width: 300px"
            v-model="ruleForm.info.legal_name"
            placeholder="请输入法人姓名"
          ></el-input>
        </div>
        <el-form-item prop="info.phone" label="联系电话：">
          <el-input
            style="max-width: 300px"
            v-model="ruleForm.info.phone"
            maxlength="11"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '产线规划送审与验收申请'"
          prop="info.factory_addr"
          label="厂房地址："
        >
          <el-input
            style="max-width: 300px"
            v-model="ruleForm.info.factory_addr"
            placeholder="请输入标准厂房地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '产线规划送审与验收申请'"
          prop="info.design_company"
          label="设计公司："
        >
          <el-input
            style="max-width: 300px"
            v-model="ruleForm.info.design_company"
            placeholder="请输入设计公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '金融产品申请'"
          prop="info.prod_line_plan_desc"
          label="产规过审情况："
        >
          <el-input
            style="max-width: 300px"
            v-model="ruleForm.info.prod_line_plan_desc"
            placeholder="请输入产规过审情况"
          ></el-input>
        </el-form-item>
        <div v-if="popTitle == '租金减免申请'" class="form-content1">
          <div class="flex">
            <span class="form-text-left">设备使用状况：</span>
            <div class="content-item-right flex-1">
              <div>按要求安装环保设备并正常使用</div>
              <el-form-item label-width="0" prop="info.is_devices_ep">
                <el-radio-group v-model="ruleForm.info.is_devices_ep">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <div class="flex">
            <span class="form-text-left">企业规模：</span>
            <div class="content-item-right flex-1">
              <div>省级规模以上企业</div>
              <el-form-item label-width="0" prop="info.is_province_level">
                <el-radio-group v-model="ruleForm.info.is_province_level">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <div class="flex">
            <span class="form-text-left">企业数据：</span>
            <div class="content-item-right flex-1">
              <div>如实上报企业数据</div>
              <el-form-item label-width="0" prop="info.is_report_data">
                <el-radio-group v-model="ruleForm.info.is_report_data">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </div>
        <el-form-item
          v-if="popTitle == '企业退规申请'"
          prop="info.quit_reason"
          label="退规理由："
        >
          <el-input
            type="textarea"
            style="max-width: 300px"
            :rows="2"
            placeholder="请输入内容"
            v-model="ruleForm.info.quit_reason"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '企业入规申请'"
          prop="info.meter_number"
          label="用电表号："
        >
          <el-input
            style="max-width: 300px"
            placeholder="请输入内容"
            v-model="ruleForm.info.meter_number"
          ></el-input>
        </el-form-item>
        <div v-if="popTitle == '租金减免申请'" class="content-attention">
          <div>申请须知</div>
          <p>1. 申请的企业为产前规划审核通过的入园规上家具企业;</p>
          <p>
            2.
            目前符合申请条件的集聚区有：东山官坑集聚区、东山文峰集聚区、镜坝200亩、镜坝128亩、镜703亩、龙回三益集聚区、龙回半岭集聚区、唐江集聚区、横寨集聚区、龙华50亩集聚区、龙华98亩集聚区;
          </p>
          <p>
            3.企业按照要求安装了环保设备并正常使用，经环保部门认定后，可将租金按每月减免1元/㎡的标准奖励给企业;
          </p>
          <p>
            4.企业已经通过省级核准为规上企业的，经区统计局认定后，从入省规的次月起，可将租金按每月减免0.5元/㎡的标准奖励给企业;
          </p>
          <p>
            5.企业按时报送企业数据的，经区统计局认定后，可将租金按每月减免0.5元/㎡的标准奖励给企业。
          </p>
        </div>
        <div v-if="popTitle == '企业退规申请'" class="content-attention">
          <div>退规须知</div>
          <p>1. 申请企业应为南康区大数据平台内注册在内的规上家具企业；</p>
          <p>2. 在线退规只接受自身原因自愿退出的企业申报；</p>
          <p>3. 转企办将在企业提交申请7个工作日内通知企业办理结果。</p>
        </div>
        <div v-if="popTitle == '企业入规申请'" class="content-attention">
          <div>申请须知</div>
          <p>1.新递补的家具企业必须同时符合以下条件：</p>
          <div>
            <p>（1）申请企业年度纳税额在30万元以上。</p>
            <p>（2）办理一般纳税人。</p>
            <p>
              （3）严格按增值税一般纳税人核算，且税务“金三系统”输出的一个会计年度的增值税纳税申请表上的主营业务收入达2000万元以上。
            </p>
            <p>（4）申请企业近两年无不良征信记录。</p>
            <p>（5）法定代表人未被法院列为失信人员。</p>
          </div>
          <p>
            2.区“转企升规”办牵头，企业所在乡（镇、街道）配合，对企业的入规资格进行审查，资格审查结束后，在7个工作日内完成入规程序，在康企通信息平台完成登记备案。
          </p>
        </div>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.companyRealCost"
          label="企业本年度实际研发设计费用："
        >
          <el-input
            style="max-width: 300px"
            v-model="ruleForm.info.companyRealCost"
            placeholder="企业本年度实际研发设计费用"
          ></el-input>
          <span class="unit">万元</span>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.applyAmount"
          label="申请奖补金额："
        >
          <el-input
            style="max-width: 300px"
            v-model="ruleForm.info.applyAmount"
            placeholder="申请奖补金额"
          ></el-input>
          <span class="unit">万元</span>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.remark"
          label="企业简介："
        >
          <el-input
            type="textarea"
            :rows="5"
            maxlength="1000"
            show-word-limit
            v-model="ruleForm.info.remark"
            placeholder="企业简介"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.businessLicense"
          label="营业执照："
        >
          <UploadImgs
            :limit="1"
            v-model="ruleForm.info.businessLicense"
            :prefix="uploadUrl"
          ></UploadImgs>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.idCard"
          label="法人身份证："
        >
          <UploadImgs
            :limit="2"
            v-model="ruleForm.info.idCard"
            :prefix="uploadUrl"
          ></UploadImgs>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          label="设计公司："
          prop="info.designCompany"
        >
          <el-select v-model="ruleForm.info.designCompany" placeholder="请选择">
            <el-option
              v-for="item in designCompany"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.designContract"
          label="设计合同："
        >
          <UploadPdf
            v-model="ruleForm.info.designContract"
            :prefix="uploadUrl"
            contract_type="5"
          ></UploadPdf>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.designDrawing"
          label="设计图纸："
        >
          <UploadImgs
            :limit="10"
            v-model="ruleForm.info.designDrawing"
            :prefix="uploadUrl"
          ></UploadImgs>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.taxInvoice"
          label="增值税发票："
        >
          <UploadImgs
            v-model="ruleForm.info.taxInvoice"
            :prefix="uploadUrl"
          ></UploadImgs>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '设计奖补申请'"
          prop="info.payProof"
          label="付款凭证："
        >
          <UploadImgs
            v-model="ruleForm.info.payProof"
            :prefix="uploadUrl"
          ></UploadImgs>
        </el-form-item>
      </div>
    </el-form>
    <div class="form-button">
      <!--<el-button @click="editing=false">取消</el-button>-->
      <el-button
        v-if="popTitle === '设计奖补申请'"
        @click="submitForm(1)"
        type="primary"
        size="small"
        >存草稿</el-button
      >
      <el-button
        v-if="popTitle === '设计奖补申请'"
        @click="submitForm(2)"
        type="primary"
        size="small"
      >
        确认</el-button
      >
      <el-button
        v-if="popTitle !== '设计奖补申请'"
        type="primary"
        size="small"
        @click="submitForm()"
        >确认提交</el-button
      >
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import {
  listSealMember,
  contractAddr,
  contractView,
  contractDownload,
} from "@/service/contract";
import {
  applySave,
  applyAllowanceSave,
  getApplyId,
  getApplyAllowanceId,
} from "@/service/factory";
export default {
  props: ["form", "popTitle"],
  computed: {
    uploadUrl() {
      return `gov/sjjb/${this.$store.state.loginUser.comp.id}`;
    },
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      showImgs: false,
      designCompany: [
        {
          id: 1,
          name: "设计公司 1",
        },
      ],
      rules: {
        [`info.phone`]: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        [`info.factory_addr`]: [
          { required: true, message: "请输入厂房地址", trigger: "blur" },
        ],
        [`info.meter_number`]: [
          { required: true, message: "请输入用电表号", trigger: "blur" },
        ],
        [`info.quit_reason`]: [
          { required: true, message: "请输入退规理由", trigger: "blur" },
        ],
        [`info.is_devices_ep`]: [
          { required: true, message: "请选择设备使用状况", trigger: "blur" },
        ],
        [`info.is_province_level`]: [
          { required: true, message: "请选择企业规模", trigger: "blur" },
        ],
        [`info.is_report_data`]: [
          { required: true, message: "请选择企业数据", trigger: "blur" },
        ],
        [`info.finance_pro_type`]: [
          { required: true, message: "请选择申请类型", trigger: "blur" },
        ],
        [`info.prod_line_plan_type`]: [
          { required: true, message: "请选择申请类型", trigger: "blur" },
        ],
        [`info.prod_line_plan_desc`]: [
          { required: true, message: "请输入产规过审情况", trigger: "blur" },
        ],
        [`info.compRange`]: [
          { required: true, message: "请输入企业服务范围", trigger: "blur" },
        ],
        [`info.design_company`]: [
          { required: true, message: "请输入设计公司名称", trigger: "blur" },
        ],
        [`info.designCompany`]: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        [`info.companyRealCost`]: [
          {
            required: true,
            message: "请输入企业实际研发费用",
            trigger: "blur",
          },
        ],
        [`info.applyAmount`]: [
          { required: true, message: "请输入企业申请金额", trigger: "blur" },
        ],
        [`info.remark`]: [
          { required: true, message: "请输入公司简介", trigger: "blur" },
        ],
        [`info.businessLicense`]: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
        [`info.idCard`]: [
          { required: true, message: "请上传身份证的正反面", trigger: "blur" },
        ],
        [`info.designContract`]: [
          { required: true, message: "请上传设计合同", trigger: "blur" },
        ],
        [`info.designDrawing`]: [
          { required: true, message: "请上传设计图纸", trigger: "blur" },
        ],
        [`info.taxInvoice`]: [
          { required: true, message: "请上传增值税发票", trigger: "blur" },
        ],
        [`info.payProof`]: [
          { required: true, message: "请上传付款凭证或截图", trigger: "blur" },
        ],
      },
      ruleForm: {},
    };
  },
  watch: {
    popTitle: {
      handler() {
        this.ruleForm = this.form;
      },
      deep: true,
    },
  },
  created() {
    this.ruleForm = this.form;
    console.log(this.ruleForm);
  },
  mounted() {
    const id = this.$route.query.id;
    if (id) {
      if (this.popTitle === "设计奖补申请") {
        this.getApplyAllowanceDetail(id);
      } else {
        getApplyId(id)
          .then((rst) => {
            if (rst) {
              this.ruleForm = {
                type: rst.type,
                info: rst.info,
                submitNow: true,
              };
            }
            console.log(rst);
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.message);
          });
      }
    }
  },
  methods: {
    getApplyAllowanceDetail(id) {
      getApplyAllowanceId(id)
        .then((rst) => {
          if (rst) {
            // this.ruleForm = {
            //   type: rst.type,
            //   info: rst.info,
            //   submitNow: true,
            // };
          }
          console.log(rst);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    submitForm(type) {
      this.$refs["formCheck"].validate((valid) => {
        if (valid) {
          this.submit(type);
        }
      });
    },
    submit(type) {
      if (this.popTitle == "设计奖补申请") {
        console.error("this.ruleForm", this.ruleForm);
        const {
          legal_name,
          phone,
          company_name,
          company_address,
          compRange,
          applyAmount,
          companyRealCost,
          remark,
          designCompany,
          businessLicense,
          idCard,
          designContract,
          designDrawing,
          taxInvoice,
          payProof,
        } = this.ruleForm.info;
        const params = {
          compName: company_name,
          compAddr: company_address,
          compRange,
          legalName: legal_name,
          legalTel: phone,
          fee: companyRealCost,
          applyAmount,
          compIntro: remark,
          desginCompId: designCompany,
          desginCompName: this.designCompany.find((v) => v.id === designCompany)
            .name,
          attachment: {
            license: businessLicense,
            idCard: {
              pros: idCard && idCard[0],
              cons: idCard && idCard[1],
            },
            contracts: designContract,
            drawings: designDrawing,
            receipts: taxInvoice,
            vouchers: payProof,
          },
        };
        const parameter =
          type === 2
            ? { ...params, submitNow: true }
            : { ...params, submitNow: false, id: this.$route.params.id };
        this.designApplySave(parameter);
      } else {
        this.apply_save();
      }
    },
    designApplySave(params) {
      applyAllowanceSave(params)
        .then((rst) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    // 发起签约
    gotoSign() {
      if (this.$store.state.loginUser.comp.cert_stat != 2) {
        this.$message.error("企业未认证");
        return;
      } else {
        this.$confirm("确认是否发起签约?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        }).then(() => {
          let obj = {
            seal_type: 1, // 印章类型，0保留，1设计作品版权出售合同章，2商品打样合同章，3代加工合同章(需求定制)
            page: 1,
            pagesize: 15,
          };
          if (this.planType == "bq_order") {
            obj.seal_type = 1;
          } else if (this.planType == "dy_order") {
            obj.seal_type = 2;
          } else {
            obj.seal_type = 3;
          }
          listSealMember(obj)
            .then((res) => {
              if (res && res.list && res.list.length > 0) {
                this.sealMember = res.list;
                this.selectSign();
              } else {
                this.going(this.item.contract_no);
              }
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        });
      }
    },
    going(contract_no, user_id) {
      this.loading = Loading.service({
        fullscreen: true,
        text: "拼命加载中....",
        target: this.$parent.$refs.load,
      });
      contractAddr(contract_no, user_id)
        .then((rst) => {
          console.log(rst);
          if (rst && rst.url) {
            this.sign(rst.url);
            // clearInterval(this.timer);
            // return;
          }
        })
        .catch((e) => {
          this.$message.error(e);
          this.loading.close();
        });
      // this.timer = setInterval(() => {

      // }, 2000);
    },
    sign(url) {
      this.loading.close();
      if (url) window.open(url);
      this.signState("签约");
    },
    apply_save() {
      applySave(this.ruleForm)
        .then((rst) => {
          this.$emit("refreshItems");
          this.$emit("hide");
          this.$message.success("提交成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    // gov_join() {
    //     govJoin(this.ruleForm).then(rst => {
    //         this.$emit("refreshItems");
    //         this.$emit("hide");
    //         this.$message.success("提交成功");
    //     }).catch(err => {
    //         this.$message.error(err.message);
    //     });
    // },
    // gov_quit() {
    //     govQuit(this.ruleForm).then(rst => {
    //         this.$emit("refreshItems");
    //         this.$emit("hide");
    //         this.$message.success("提交成功");
    //     }).catch(err => {
    //         this.$message.error(err.message);
    //     });
    // },
    // gov_reductionRent() {
    //     govReductionRent(this.ruleForm).then(rst => {
    //         this.$emit("refreshItems");
    //         this.$emit("hide");
    //         this.$message.success("提交成功");
    //     }).catch(err => {
    //         this.$message.error(err.message);
    //     });
    // },
    // gov_finance() {
    //     govFinance(this.ruleForm).then(rst => {
    //         this.$emit("refreshItems");
    //         this.$emit("hide");
    //         this.$message.success("提交成功");
    //     }).catch(err => {
    //         this.$message.error(err.message);
    //     });
    // },
    // gov_prodPlan() {
    //     govProdPlan(this.ruleForm).then(rst => {
    //         this.$emit("refreshItems");
    //         this.$emit("hide");
    //         this.$message.success("提交成功");
    //     }).catch(err => {
    //         this.$message.error(err.message);
    //     });
    // },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.pop-form {
  font-size: 14px;
  font-weight: 500;
  color: rgba(128, 128, 128, 1);
  background: #fff;
}
.pop-form-header {
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(41, 41, 41, 1);
  border-bottom: 1px solid #e6e6e6;
}
.form-content {
  padding: 20px;
}
.form-content > div {
  margin-bottom: 20px;
}
.form-content > div:last-child {
  margin-bottom: 0;
}
.form-content > div .form-text-left {
  width: 125px;
  text-align: right;
  padding-right: 12px;
  display: inline-block;
  font-size: 14px;
  color: #4d4d4d;
}

.form-button {
  padding: 15px 20px;
  border-top: 1px solid #e6e6e6;
}
.form-content1 .content-item-right {
  height: 85px;
}
.content-item-right > div:first-child {
  margin-bottom: 5px;
  font-size: 12px;
  color: #808080;
}

/* --------注意--------- */
.content-attention {
  padding: 15px 20px;
  background: #f5f5f5;
}
.content-attention > div {
  font-size: 14px;
  color: #4d4d4d;
  line-height: 20px;
  margin-bottom: 10px;
}
.content-attention p {
  font-size: 12px;
  color: #808080;
  line-height: 17px;
  margin-bottom: 5px;
}
.content-attention > div p {
  padding-left: 10px;
}
</style>
<style lang="less" scoped>
.unit {
  margin-left: 40px;
}
</style>